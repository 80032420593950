import React, { createContext, useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [socketData, setSocketData] = useState({});
  const [isConnectedWs1, setIsConnectedWs1] = useState(false); 
  const [isConnectedWs2, setIsConnectedWs2] = useState(false); 
  const [ws1, setWs1] = useState(null); 
  const [ws2, setWs2] = useState(null); 

  const api_key = 'oe0zgbgzlbuqdmra';

  const [userToken, setUserToken] = useState(Cookies.get('userToken'));
  const [userId, setUserId] = useState(Cookies.get('userId'));

  const wsUrl1 = userToken && userId

    ? `wss://real-time.smartcapital.co.in?api_key=${api_key}&x-access-token=${userToken}&user-id=${userId}`
    : null;
    // ? `ws://localhost:8002?api_key=${api_key}&x-access-token=${userToken}&user-id=${userId}`
    // : null;

  const wsUrl2 = userToken && userId
    ? `wss://option.smartcapital.co.in?api_key=${api_key}&x-access-token=${userToken}&user-id=${userId}`
    : null;
    // ? `ws:/localhost:8003?api_key=${api_key}&x-access-token=${userToken}&user-id=${userId}`
    // : null;

  useEffect(() => {
    if (!userToken || !userId) {
      const interval = setInterval(() => {
        const token = Cookies.get('userToken');
        const id = Cookies.get('userId');
        
        if (token && id) {
          setUserToken(token);
          setUserId(id);
          clearInterval(interval); 
        }
      }, 2000);
      
      return () => clearInterval(interval);
    }
  }, [userToken, userId]);

  useEffect(() => {
    if (userToken && userId) {
      // First WebSocket
      if (wsUrl1 && !ws1) {
        const webSocket1 = new WebSocket(wsUrl1);
        setWs1(webSocket1);

        webSocket1.onopen = () => {
          console.log('First WebSocket connection established');
          setIsConnectedWs1(true);
        };

        webSocket1.onmessage = (event) => {
          const data = JSON.parse(event.data);

          if (data.message) {
            const accessToken = data.accessToken;
            if (accessToken) {
              Cookies.set('accessToken', accessToken);
            }
          }

          if (Array.isArray(data) && data.length > 0) {
            data.forEach(stockUpdate => {
              const { instrument_token, last_price, change, ohlc, exchange_timestamp, depth } = stockUpdate;

              setSocketData(prevSocketData => ({
                ...prevSocketData,
                [instrument_token]: {
                  last_price,
                  net_change: change,
                  ohlc,
                  exchange_timestamp,
                  depth,
                },
              }));
            });
          }
        };

        webSocket1.onerror = (error) => {
          console.error('First WebSocket error:', error);
          setTimeout(() => {
            setIsConnectedWs1(false);
            webSocket1.close();
          }, 5000); 
        };
      }

      // Second WebSocket
      if (wsUrl2 && !ws2) {
        const webSocket2 = new WebSocket(wsUrl2);
        setWs2(webSocket2);

        webSocket2.onopen = () => {
          console.log('Second WebSocket connection established');
          setIsConnectedWs2(true);
        };

        webSocket2.onmessage = (event) => {
          const data = JSON.parse(event.data);

          if (Array.isArray(data) && data.length > 0) {
            data.forEach(stockUpdate => {
              const { instrument_token, last_price, change, ohlc, exchange_timestamp,depth } = stockUpdate;

              setSocketData(prevSocketData => ({
                ...prevSocketData,
                [instrument_token]: {
                  last_price,
                  net_change: change,
                  ohlc,
                  exchange_timestamp,
                  depth,
                },
              }));
            });
          }
        };

        webSocket2.onerror = (error) => {
          console.error('Second WebSocket error:', error);
          setTimeout(() => {
            setIsConnectedWs2(false);
            webSocket2.close();
          }, 5000); 
        };
      }
    }
  }, [userToken, userId, wsUrl1, wsUrl2]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Reconnect WebSocket 1 if not connected
      if (ws1 && !isConnectedWs1) {
        console.log('Reconnecting to First WebSocket...');
        const webSocket1 = new WebSocket(wsUrl1);
        setWs1(webSocket1);

        webSocket1.onopen = () => {
          console.log('First WebSocket reconnected');
          setIsConnectedWs1(true);
        };

        webSocket1.onerror = () => {
          setIsConnectedWs1(false);
        };
      }

      // Reconnect WebSocket 2 if not connected
      if (ws2 && !isConnectedWs2) {
        console.log('Reconnecting to Second WebSocket...');
        const webSocket2 = new WebSocket(wsUrl2);
        setWs2(webSocket2);

        webSocket2.onopen = () => {
          console.log('Second WebSocket reconnected');
          setIsConnectedWs2(true);
        };

        webSocket2.onerror = () => {
          setIsConnectedWs2(false);
        };
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [wsUrl1, wsUrl2, isConnectedWs1, isConnectedWs2]);

  return (
    <WebSocketContext.Provider value={{ socketData, isConnectedWs1, isConnectedWs2 }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketData = () => {
  return useContext(WebSocketContext);
};

import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { Card, Typography, Box, IconButton, FormControl, Select, MenuItem } from "@mui/material";
import { ThemeContext } from "../../theme-context";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import config from "../../config";
import Swal from "sweetalert2";
import watchlist from "../../Assests/Watchlist.png";
import { createChart } from "lightweight-charts";
import { useWebSocketData } from "../../Context/WebSocketContext";
import errorIllustration from "../../Assests/chart.png"; 
import { useNavigate } from 'react-router-dom';

const ShimmerLoader = ({ width, height }) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        background: "#f6f7f8",
        position: "relative",
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: "-100%",
          width: "100%",
          height: "100%",
          background: "linear-gradient(90deg, #f6f7f8 25%, #e0e0e0 50%, #f6f7f8 75%)",
          animation: "loading 1.5s infinite",
        }}
      />
    </div>
  );
};

export default function StockChartFno() {
  const { theme } = useContext(ThemeContext);
  const [chartPeriod, setChartPeriod] = useState("30minute");
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { instrumentToken, exchange } = useParams();
  const [companyDetails, setCompanyDetails] = useState({ name: "", exchange: "", tradingsymbol: "", lot_size: 0 });
  const chartContainerRef = useRef(null);
  const [chartAvailable, setChartAvailable] = useState(true);
  const { socketData } = useWebSocketData();
  const navigate = useNavigate(); 

  const fetchStockData = async () => {
    const accessToken = Cookies.get("accessToken");
  
    try {
      setLoading(true);
      const currentDate = new Date();
      const toDate = new Date(currentDate);
      toDate.setDate(currentDate.getDate() - 90);

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day}+${hours}:${minutes}:${seconds}`;
      };

      const fromDateString = formatDate(currentDate);
      const toDateString = formatDate(toDate);

      const apiUrl = `${config.apiBaseUrl}/stock/historicalData`;
      const response = await axios.get(apiUrl, {
        headers: {
          "instrument-token": instrumentToken,
          interval: chartPeriod,
          from: toDateString,
          to: fromDateString,
          "access-token": accessToken,
        },
      });

      if (response.data) {
        const errorStatusCodes = [400, 403, 404, 405, 410, 429, 502, 500, 503, 504];
        
        if (errorStatusCodes.includes(response.data.statusCode)) {
          Cookies.remove("userToken");
          Cookies.remove("userId");  
          navigate('/');  
          return;  
        }
        if (response.data.data && response.data.data.candles) {
          const formattedData = response.data.data.candles.map((candle) => ({
            time: new Date(candle[0]).getTime() / 1000,
            open: candle[1],
            high: candle[2],
            low: candle[3],
            close: candle[4],
          }));
          setStockData(formattedData);
          setChartAvailable(true);
        } else {
          setChartAvailable(false);
          setError("Chart data not available, please refresh.");
        }
      } else {
        setChartAvailable(false);
        setError("Invalid response structure.");
      }
    } catch (error) {
      setChartAvailable(false);
      setError("Failed to fetch stock data. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  const fetchCompanyDetails = async () => {
    try {
      const apiUrl = `${config.apiBaseUrl}/stock/getStockByInstrumentToken`;
      const response = await axios.get(apiUrl, {
        headers: {
          "instrument-token": instrumentToken,
          "market-type": exchange,
        },
      });

      if (response.status === 200 && response.data) {
        setCompanyDetails({
          name: response.data.name || "NA",
          exchange: response.data.exchange || "NA",
          tradingsymbol: response.data.tradingsymbol || "NA",
          upper_circuit_limit: response.data.upper_circuit_limit || "NA",
          lower_circuit_limit: response.data.lower_circuit_limit || "NA",
          last_price: response.data.last_price,
        });
      } else {
        setError("Unexpected data format or empty response from the server.");
      }
    } catch (error) {
      setError("Error fetching company details. Please try again.");
    }
  };

  useEffect(() => {
    fetchStockData();
    fetchCompanyDetails();
  }, [chartPeriod, instrumentToken, exchange]);

  useEffect(() => {
    if (stockData.length && chartContainerRef.current) {
      const sortedData = stockData.sort((a, b) => a.time - b.time);

      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: 400,
        layout: {
          backgroundColor: "transparent",
          textColor: theme === "dark" ? "#000000" : "#000000",
        },
        grid: {
          vertLines: { color: theme === "dark" ? "#555" : "#ddd" },
          horzLines: { color: theme === "dark" ? "#555" : "#ddd" },
        },
        priceScale: {
          borderColor: theme === "dark" ? "#555" : "#ddd",
        },
        crosshair: {
          vertLine: {
            color: theme === "dark" ? "#555" : "#ddd",
          },
          horzLine: {
            color: theme === "dark" ? "#555" : "#ddd",
          },
        },
      });

      const candlestickSeries = chart.addCandlestickSeries({
        upColor: "green",
        borderUpColor: "green",
        wickUpColor: "green",
        downColor: "red",
        borderDownColor: "red",
        wickDownColor: "red",
      });

      candlestickSeries.setData(sortedData);

      return () => chart.remove();
    }
  }, [stockData, theme]);

  const handleAddToWatchlist = async () => {
    const userId = Cookies.get("userId");
    const userToken = Cookies.get("userToken");
    const instrumentTokenNumber = Number(instrumentToken);

    try {
      const apiUrl = `${config.apiBaseUrl}/user/addToWatchList`;
      const response = await axios.post(
        apiUrl,
        {
          instrument_tokens: [instrumentTokenNumber],
        },
        {
          headers: {
            "user-id": userId,
            "x-access-token": userToken,
          },
        }
      );

      if (response.status === 200) {
        Swal.fire("Success", "Stock added to watchlist!", "success");
      } else {
        Swal.fire("Error", "Failed to add stock to watchlist.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Error: Unable to add stock to watchlist.", "error");
    }
  };

  // if (loading) {
  //   return (
  //     <div
  //       style={{
  //         minHeight: "100vh",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         backgroundColor: theme === "dark" ? "#121212" : "#f5f5f5",
  //       }}
  //     >
  //       <CircularProgress />
  //       <Typography variant="h6" style={{ marginLeft: "16px" }}>
  //         Loading Stock Data...
  //       </Typography>
  //     </div>
  //   );
  // }

  if (error) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme === "dark" ? "#121212" : "#f5f5f5",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </div>
    );
  }

  const latestPrice = socketData[instrumentToken]?.last_price || companyDetails.last_price || 0;

  return (
    <div>
      <Card
        style={{
          backgroundColor: theme === "dark" ? "#181818" : "#e6e6e6",
          color: theme === "dark" ? "#fff" : "#000",
          height: "530px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px" }}>
          {companyDetails.name ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
                width: "100%",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  marginRight: "8px",
                  color: theme === "dark" ? "#fff" : "#000",
                }}
              >
                {companyDetails.name}
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  flex: 1,
                  display: { xs: 'none', sm: 'block' },  
                }}
              >
                {companyDetails.exchange}
              </Typography>


              {stockData.length > 0 && (
                <Box
                  display="flex"
                  gap={2}
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "inline-flex", alignItems: "center", gap: "2px" }}>
                    <Typography
                      variant="body1"
                      style={{
                        color: theme === "dark" ? "white" : "black",
                      }}
                    >
                      C
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color:
                          stockData[stockData.length - 1]?.close > stockData[stockData.length - 2]?.close
                            ? "green"
                            : "red",
                      }}
                    >
                      ₹{latestPrice}
                    </Typography>
                  </div>

                  <FormControl
                    sx={{
                      minWidth: 80,
                      border: theme === 'dark' ? '1px solid white' : '1px solid black',
                      borderRadius: 1,
                      '&.Mui-focused': {
                        borderColor: theme === 'dark' ? 'white' : 'black',
                      },
                      '@media (max-width: 600px)': {
                        minWidth: 50, 
                      },
                    }}
                  >
                    <Select
                      value={chartPeriod}
                      onChange={(e) => setChartPeriod(e.target.value)}
                      sx={{
                        fontSize: '0.875rem',  
                        color: theme === 'dark' ? 'white' : 'black',
                        '& .MuiSelect-icon': {
                          color: theme === 'dark' ? 'white' : 'black',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme === 'dark' ? 'white' : 'black',
                        },
                        '&:focus': {
                          borderColor: theme === 'dark' ? 'white' : 'black',
                          boxShadow: 'none',
                        },
                        '@media (max-width: 600px)': {
                          fontSize: '0.75rem',  
                          padding: '3px',  
                        },
                      }}
                    >
                      <MenuItem value="3minute">3 Minute</MenuItem>
                      <MenuItem value="5minute">5 Minute</MenuItem>
                      <MenuItem value="10minute">10 Minute</MenuItem>
                      <MenuItem value="15minute">15 Minute</MenuItem>
                      <MenuItem value="30minute">30 Minute</MenuItem>
                      <MenuItem value="60minute">60 Minute</MenuItem>
                      <MenuItem value="day">Day</MenuItem>
                    </Select>
                  </FormControl>


                  <IconButton
                    onClick={() => handleAddToWatchlist(companyDetails.name)}
                    color="primary"
                    style={{ padding: 8 }}
                  >
                    <img
                      src={watchlist}
                      alt="Add to Watchlist"
                      style={{ width: "24px", height: "24px" }}
                    />
                  </IconButton>
                </Box>
              )}
            </div>
          ) : (
            <div>
              <Typography variant="h5">Stock Price Chart</Typography>
            </div>
          )}
        </div>

        {/* {loading ? (
          <div
            ref={chartContainerRef}
            style={{
              width: "100%",
              height: "400px",
              flexGrow: 1,
            }}
          >
            <ShimmerLoader width="100%" height="400px" />
          </div>
        ) : (
          <div
            ref={chartContainerRef}
            style={{
              width: "100%",
              height: "400px",
              flexGrow: 1,
            }}
          ></div>
        )} */}

        {loading ? (
          <ShimmerLoader width="100%" height="400px" />
        ) : (
          chartAvailable  ? (
            <div
              ref={chartContainerRef}
              style={{
                width: "100%",
                height: "400px",
                flexGrow: 1,
              }}
            ></div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={errorIllustration}
                alt="Chart Error"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          )
        )}
      </Card>
    </div>
  );
}

import React from 'react';
import { Box, Grid, ThemeProvider, createTheme } from '@mui/material';
import StockChart from './StockChart';  
import StockBuyCard from './StockBuyCard'; 
import StockDetailsProfile from  './StockDetailsProfile';  

const cruidoil = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300, 
        xl: 1536,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: 2 }}>
        <Grid 
          container 
          spacing={2} 
          sx={{ 
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          <Grid item xs={12} lg={8}>
            <Box sx={{ padding: 0.5, height: '500px', marginBottom: '20px' }}>
              <StockChart />
            </Box>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Box sx={{ padding: 0.5 }}>
              <StockBuyCard />
            </Box>
          </Grid>

          <Box sx={{ width: '100%' }}>
            <StockDetailsProfile />  
          </Box>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default cruidoil;

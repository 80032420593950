import React, { useState, useContext, useEffect } from 'react';
import { TextField, Box, Button, Typography, Grid, CircularProgress } from '@mui/material';
import { ThemeContext } from '../../theme-context';
import { Paperclip } from 'lucide-react';
import Cookies from 'js-cookie';
import axios from 'axios';
import Swal from 'sweetalert2';
import config from '../../config';
import approved from '../../Assests/approved.png';
import pendingIllustration from '../../Assests/pending.png';
import rejectedIllustration from '../../Assests/rejected.png';
import imageCompression from 'browser-image-compression';


const KYC = () => {
  const [aadharNumber, setAadharNumber] = useState('');
  const [reEnterAadharNumber, setReEnterAadharNumber] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [aadharFrontImage, setAadharFrontImage] = useState(null);
  const [aadharBackImage, setAadharBackImage] = useState(null);
  const [panFrontImage, setPanFrontImage] = useState(null);
  const [kycStatus, setKycStatus] = useState(null);
  const [kycAttempt, setKYCAttempt] = useState(1);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [buttonOpacity, setButtonOpacity] = useState(1);
  const { theme } = useContext(ThemeContext);

  const getThemeColors = () => {
    return {
      text: theme === 'dark' ? '#fff' : '#111',
      background: theme === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
      borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      uploadBg: theme === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.01)',
    };
  };

  const colors = getThemeColors();

  const validateAadhar = (aadhar) => /^\d{12}$/.test(aadhar);
  const validatePan = (pan) => /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(pan);

  const validateForm = () => {
    if (!validateAadhar(aadharNumber)) {
      Swal.fire({
        title: 'Error!',
        text: 'Please enter a valid Aadhar number (12 digits).',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return false;
    }

    if (aadharNumber !== reEnterAadharNumber) {
      Swal.fire({
        title: 'Error!',
        text: 'Aadhar number and re-entered Aadhar number do not match.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return false;
    }

    if (!validatePan(panNumber)) {
      Swal.fire({
        title: 'Error!',
        text: 'Please enter a valid Pan number (5 letters, 4 digits, 1 letter).',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return false;
    }

    if (!aadharFrontImage || !aadharBackImage || !panFrontImage) {
      Swal.fire({
        title: 'Error!',
        text: 'Please upload all the required images (Aadhar front, Aadhar back, Pan front).',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return false;
    }

    return true;
  };

  const handleFileChange = async (e, type) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 500,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);

        switch (type) {
          case 'aadharFront':
            setAadharFrontImage(compressedFile);
            break;
          case 'aadharBack':
            setAadharBackImage(compressedFile);
            break;
          case 'panFront':
            setPanFrontImage(compressedFile);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error('Image compression error:', error);
        Swal.fire({
          title: 'Error!',
          text: 'An error occurred while compressing the image. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const handleSubmit = () => {
    if (kycStatus === 'approved') {
      Swal.fire({
        title: 'Error!',
        text: 'KYC has already been approved.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }

    if (!validateForm()) return;

    const userId = Cookies.get('userId');
    const accessToken = Cookies.get('userToken');

    if (!userId || !accessToken) {
      Swal.fire({
        title: 'Error!',
        text: 'User is not authenticated. Please login again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }

    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('panCard', panNumber);
    formData.append('aadharCard', aadharNumber);
    formData.append('panCardFront', panFrontImage);
    formData.append('aadharCardFront', aadharFrontImage);
    formData.append('aadharCardBack', aadharBackImage);

    const apiUrl = `${config.apiBaseUrl}/user/submitKYC`;

    setSubmitting(true);
    setButtonOpacity(0.5);

    axios
      .post(apiUrl, formData, {
        headers: {
          'x-access-token': accessToken,
        },
      })
      .then((response) => {
        const successMessage = response.data.message || 'KYC details have been saved successfully.';
        Swal.fire({
          title: 'Success!',
          text: successMessage,
          icon: 'success',
          confirmButtonText: 'OK',
        });
        setSubmitting(false);
        setButtonOpacity(1);
        window.location.reload();
      })
      .catch((error) => {
        let errorMessage = 'Check Image Size or Try Again';

        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 400) {
            errorMessage = 'Invalid data format. Please check the data and try again.';
          } else if (statusCode === 498) {
            errorMessage = 'KYC is already submitted and cannot be resubmitted at this time.';
          } else if (statusCode === 413) {
            errorMessage = 'Image size is too large. Please upload a smaller image.';
          } else {
            errorMessage = error.response.data.message || errorMessage;
          }
        }

        Swal.fire({
          title: 'Error!',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'OK',
        });
        setSubmitting(false);
        setButtonOpacity(1);
      });
  };

  useEffect(() => {
    const userId = Cookies.get('userId');
    const accessToken = Cookies.get('userToken');

    if (userId && accessToken) {
      const apiUrl = `${config.apiBaseUrl}/user/getKycStatus`;
      axios
        .get(apiUrl, {
          headers: {
            'user-id': userId,
            'x-access-token': accessToken,
          },
        })
        .then((response) => {
          const kycStatus = response.data.kycStatus;
          const attempt = response.data.isAttempt;
          console.log(kycStatus);
          setKYCAttempt(attempt);
          setKycStatus(kycStatus === 'Approved' ? 'approved' : kycStatus === 'Rejected' ? 'rejected' : kycStatus === 'In Progress' && !attempt ? null : 'pending');
        })
        .catch((error) => {
          console.error('Error fetching profile data:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);


  const UploadBox = ({ label, onFileChange, image }) => {
    const fileInputRef = React.useRef(null);

    const handleClick = () => {
      fileInputRef.current.click();
    };

    return (
      <Box
        sx={{
          border: '2px dashed',
          borderColor: colors.borderColor,
          borderRadius: '8px',
          p: 3,
          textAlign: 'center',
          backgroundColor: colors.uploadBg,
          cursor: 'pointer',
          '&:hover': {
            borderColor: '#1a73e8',
          },
        }}
        onClick={handleClick}
      >
        <Paperclip size={24} style={{ color: colors.text, opacity: 0.5, marginBottom: 8 }} />
        <Typography variant="body2" style={{ color: colors.text, opacity: 0.7 }} >
          {label}
        </Typography>
        {image && (
          <Typography variant="body2" style={{ color: colors.text, marginTop: 8 }} >
            {image.name}
          </Typography>
        )}
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={onFileChange}
        />
      </Box>
    );
  };

  return (
    <div style={{ padding: '0 16px 32px', minHeight: '300px' }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "24px" }}>
        <Typography variant="h6" style={{ color: colors.text }}>
          KYC Information
        </Typography>
        {/* <Typography variant="body2" style={{ color: "orange", fontWeight: "bold" }}>
          Resubmit Your KYC
        </Typography> */}
      </div>

      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress size={50} style={{ color: colors.text }} />
        </div>
      ) : kycStatus === 'approved' ? (
        <div style={{ textAlign: 'center' }}>
          <img src={approved} alt="KYC Approved" style={{ height: '200px', maxWidth: '100%' }} />
        </div>
      ) : kycStatus === 'pending' ? (
        <div style={{ textAlign: 'center' }}>
          <img src={pendingIllustration} alt="KYC Pending" style={{ height: '200px', maxWidth: '100%' }} />
        </div>
      ) : kycStatus === 'rejected' ? (
        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img
            src={rejectedIllustration}
            alt="KYC Rejected"
            style={{ height: '200px', maxWidth: '100%' }}
          />
          {/* <button
            style={{
              marginTop: '10px',
              padding: '10px 20px',
              borderRadius: '25px',
              border: 'none',
              backgroundColor: '#FF4C4C',
              color: 'white',
              fontSize: '16px',
              cursor: 'pointer',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => setKycStatus(null)}
          >
            Retry KYC
          </button> */}
        </div>


      ) : (
        <Grid container spacing={3} style={{ maxWidth: '100%' }}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Aadhar Number"
              value={aadharNumber}
              onChange={(e) => setAadharNumber(e.target.value)}
              variant="outlined"
              InputProps={{
                style: {
                  backgroundColor: colors.background,
                  color: colors.text,
                },
              }}
              InputLabelProps={{
                style: { color: colors.text },
              }}
            />
            <Typography variant="caption" style={{ display: 'block', marginTop: '8px', color: colors.text }} >
              Upload front img
            </Typography>
            <Box mt={1}>
              <UploadBox label="Upload front img" onFileChange={(e) => handleFileChange(e, 'aadharFront')} image={aadharFrontImage} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Re-enter Aadhar Number"
              value={reEnterAadharNumber}
              onChange={(e) => setReEnterAadharNumber(e.target.value)}
              variant="outlined"
              InputProps={{
                style: {
                  backgroundColor: colors.background,
                  color: colors.text,
                },
              }}
              InputLabelProps={{
                style: { color: colors.text },
              }}
            />
            <Typography variant="caption" style={{ display: 'block', marginTop: '8px', color: colors.text }}>
              Upload back img
            </Typography>
            <Box mt={1}>
              <UploadBox label="Upload back img" onFileChange={(e) => handleFileChange(e, 'aadharBack')} image={aadharBackImage} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Pan Number"
              value={panNumber}
              onChange={(e) => setPanNumber(e.target.value)}
              variant="outlined"
              InputProps={{
                style: {
                  backgroundColor: colors.background,
                  color: colors.text,
                },
              }}
              InputLabelProps={{
                style: { color: colors.text },
              }}
            />
            <Typography variant="caption" style={{ display: 'block', marginTop: '8px', color: colors.text }} >
              Upload front img
            </Typography>
            <Box mt={1}>
              <UploadBox label="Upload front img" onFileChange={(e) => handleFileChange(e, 'panFront')} image={panFrontImage} />
            </Box>
          </Grid>
        </Grid>
      )}

      {/* Show submit button only if KYC is not approved or rejected */}
      {!loading && kycStatus !== 'approved' && kycStatus !== 'rejected' && kycStatus !== 'pending' && (
        <div style={{ marginTop: '24px', display: 'flex', gap: '12px' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#1a73e8',
              color: 'white',
              textTransform: 'none',
              opacity: buttonOpacity,
            }}
            onClick={handleSubmit}
            disabled={submitting}
          >
            {submitting ? 'Submitting...' : 'Submit'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default KYC;


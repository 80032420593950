import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Box, Button, List, Typography, Paper, IconButton, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { ThemeContext } from '../../theme-context';
import { useWebSocketData } from '../../Context/WebSocketContext';
import config from '../../config';
import NoDataIllustrator from '../../Assests/F&O.png';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Swal from 'sweetalert2';
import { ArrowUpRight } from 'lucide-react';

export default function PortfolioFno() {
  const [holdings, setHoldings] = useState([]);
  const { theme } = useContext(ThemeContext);
  const { socketData } = useWebSocketData();
  const [totalInvestedAmount, setTotalInvestedAmount] = useState(0);
  const [stockPrices, setStockPrices] = useState({});  
  const isDark = theme === 'dark';
  const [loading, setLoading] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedHolding, setSelectedHolding] = useState(null);
  const [orderType, setOrderType] = useState('market');
  const [limitPrice, setLimitPrice] = useState('');
  const [quantityToSell, setQuantityToSell] = useState(''); 

  const fetchPortfolio = async () => {
    try {
      const token = Cookies.get('userToken');
      const userId = Cookies.get('userId');

      const response = await axios.get(`${config.apiBaseUrl}/order/portfolio`, {
        headers: {
          'x-access-token': token,
          'user-id': userId,
          'trade-type': 'option',
        },
      });

      if (response.data.portfolio) {
        setHoldings(response.data.portfolio);
        setTotalInvestedAmount(response.data.totalInvestedAmount);
      }
    } catch (error) {
      console.error('Error fetching portfolio:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyDetails = async (instrument_token, stockType) => {
    try {
      const apiUrl = `${config.apiBaseUrl}/stock/getStockByInstrumentToken`;
      const response = await axios.get(apiUrl, {
        headers: {
          "instrument-token": instrument_token,
          "market-type": stockType,
        },
      });

      if (response.status === 200 && response.data) {
        setStockPrices((prevPrices) => ({
          ...prevPrices,
          [instrument_token]: response.data.last_price || 0,
        }));
      }
    } catch (error) {
      console.error("Error fetching company details. Please try again.", error);
    }
  };

  useEffect(() => {
    fetchPortfolio();
  }, []);

  useEffect(() => {
    if (holdings.length > 0) {
      holdings.forEach(holding => {
        fetchCompanyDetails(holding.instrument_token, holding.stockType);
      });
    }
  }, [holdings]);

  const getLastPrice = (instrument_token) => {
    const socketPrice = socketData[instrument_token]?.last_price;
    return socketPrice || stockPrices[instrument_token] || 0;
  };

  const activeHoldings = holdings;

  const sellStock = async () => {
    try {
      const token = Cookies.get('userToken');
      const userId = Cookies.get('userId');
       const accessToken = Cookies.get("accessToken");
      
      const price = orderType === 'market' ? getLastPrice(selectedHolding.instrument_token) : limitPrice;

      setLoading(true);

      const response = await axios.post(
        `${config.apiBaseUrl}/order/sell`,
        {
          instrument_token: selectedHolding.instrument_token,
          symbol: selectedHolding.symbol,
          quantity: quantityToSell,
          price,
          tradeType: 'equity',
          stockType: selectedHolding.stockType,
          userId: userId,
          orderType: orderType,
        },
        {
          headers: {
            'x-access-token': token,
            "kite-token": accessToken
          },
        }
      );
      handleCloseDialog();
      if (response.status === 201) {
        Swal.fire({
          title: 'Success!',
          text: 'Stock sold successfully.',
          icon: 'success',
          confirmButtonText: 'Okay',
        });

        fetchPortfolio();
      } else {
        handleCloseDialog();
        Swal.fire({
          title: 'Error',
          text: response.data.message || 'Something went wrong!',
          icon: 'error',
          confirmButtonText: 'Okay',
        });
      }
    } catch (error) {
      handleCloseDialog();
      console.error("Error selling stock:", error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to sell stock. Please try again later.',
        icon: 'error',
        confirmButtonText: 'Okay',
      });
    }
    setLoading(false);
  };

  const handleOpenDialog = (holding) => {
    setSelectedHolding(holding);
    setQuantityToSell(holding.quantity); 
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOrderType('market');
    setLimitPrice('');
    setQuantityToSell(''); 
  };

  const formattedPrice = (price) => price.toFixed(2);

  const calculateReturn = (quantity, lastPrice, investedAmount, hoildingActivity) => {
    const totalReturn = ((quantity * lastPrice) - investedAmount) + hoildingActivity;
    const formattedReturn = totalReturn.toFixed(2);
    return { totalReturn: formattedReturn, isPositive: totalReturn >= 0 };
  };

  const calculateTotalReturn = () => {
    return activeHoldings.reduce((acc, position) => {
      const lastPrice = getLastPrice(position.instrument_token);
      const { totalReturn } = calculateReturn(position.quantity, lastPrice, position.investedAmount, position.hoildingActivity);
      return acc + parseFloat(totalReturn);
    }, 0).toFixed(2);
  };

  const totalReturn = calculateTotalReturn();

  return (
    <Box sx={{ maxWidth: '100%', margin: '0 auto', p: 2, minHeight: '300px', backgroundColor: isDark ? '#121212' : '#fff' }}>
      {loading ? ( 
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '90vh' }}>
          <CircularProgress />
        </Box>
      ) : activeHoldings.length === 0 ? ( 
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <img src={NoDataIllustrator} alt="No Data" style={{ width: '400px', height: 'auto' }} />
        </Box>
      ) : ( 
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 1.5, fontSize: '0.875rem', fontWeight: 400, color: isDark ? '#fff' : '#333' }}>
              F&O Positions ({activeHoldings.length})
            </Typography>
          </Box>
        
          <Typography sx={{ fontSize: '0.875rem', color: 'rgb(102, 102, 102)', mb: 2 }}>
            Total returns
            <Typography
              component="span"
              sx={{
                display: 'block',
                color: totalReturn < 0 ? 'rgb(255, 0, 0)' : 'rgb(22, 163, 74)', 
                fontSize: '1.25rem',
                fontWeight: 500,
              }}
            >
              {totalReturn < 0 ? `₹${totalReturn}` : `+₹${totalReturn}`} 
            </Typography>
          </Typography>
          
          <List sx={{ p: 0 }}>
            {activeHoldings.map((position, index) => {
              const lastPrice = getLastPrice(position.instrument_token);
              const { totalReturn, isPositive } = calculateReturn(position.quantity, lastPrice, position.investedAmount, position.hoildingActivity);
              const isInactive = !position.isActive; 

              return (
                <Paper
                  key={index}
                  elevation={0}
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.06)',
                    borderRadius: 1.5,
                    mb: 1.5,
                    p: 2,
                    backgroundColor: isDark ? '#333' : '#fff',
                    opacity: isInactive ? 0.5 : 1, 
                    pointerEvents: isInactive ? 'none' : 'auto',
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5, alignItems: 'center' }}>
                    <Typography
                      sx={{
                        color: position.isActive ? (isDark ? '#76c7f3' : 'rgb(0, 102, 255)') : 'red',
                        fontSize: '1rem',
                        fontWeight: 500,
                      }}
                    >
                      {position.symbol}
                    </Typography>
                    <IconButton
                      onClick={() => handleOpenDialog(position)}
                      color="secondary"
                      sx={{ ml: 1 }}
                      disabled={isInactive}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr auto',
                      gap: 1,
                      '& .MuiTypography-root': {
                        fontSize: '0.875rem',
                        lineHeight: 1.5,
                        color: isDark ? '#ccc' : 'rgb(102, 102, 102)',
                      },
                    }}
                  >
                    <Box>
                      <Typography>Invested ₹{formattedPrice(position.investedAmount)}</Typography>
                      <Typography>Qty. {position.quantity}</Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: '0.875rem',
                          fontWeight: 500,
                          color: isPositive ? 'green' : 'red',
                        }}
                      >
                        Total Return {isPositive ? `+₹${totalReturn}` : `₹${totalReturn}`}
                      </Typography>
                      <Typography>Avg. ₹{formattedPrice(position.avgPrice)}</Typography>
                      <Typography>₹{formattedPrice(lastPrice)} (Mkt. Price)</Typography>
                    </Box>
                  </Box>
                </Paper>
              );
            })}
          </List>

          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle sx={{ paddingBottom: 2, fontWeight: 600 }}>
              Sell {selectedHolding?.symbol}
            </DialogTitle>
            <DialogContent sx={{ padding: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    onClick={() => setOrderType('market')}
                    variant={orderType === 'market' ? 'contained' : 'outlined'}
                    color="primary"
                    sx={{ width: '48%' }}
                  >
                    Market
                  </Button>
                  <Button
                    onClick={() => setOrderType('limit')}
                    variant={orderType === 'limit' ? 'contained' : 'outlined'}
                    color="primary"
                    sx={{ width: '48%' }}
                  >
                    Limit
                  </Button>
                </Box>

                <TextField
                  label="Quantity"
                  type="number"
                  value={quantityToSell}
                  onChange={(e) => setQuantityToSell(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  sx={{ marginTop: 2 }}
                />

                {orderType === 'limit' && (
                  <TextField
                    label="Limit Price"
                    type="number"
                    value={limitPrice}
                    onChange={(e) => setLimitPrice(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{ marginTop: 2 }}
                  />
                )}
              </Box>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
              <Button onClick={handleCloseDialog} color="secondary">
                Cancel
              </Button>
              <Button onClick={sellStock} color="primary">
                Sell
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}

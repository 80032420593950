import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light'); 

  useEffect(() => {

    const themeListener = window.matchMedia('(prefers-color-scheme: dark)');
    themeListener.addEventListener('change', (e) => {
      setTheme(e.matches ? 'dark' : 'light');
    });

    return () => {
      themeListener.removeEventListener('change', () => {});
    };
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

import React, { useContext, useState, useEffect } from "react";
import { Wallet } from 'lucide-react';
import { Button, Card, CardContent, Grid, Input, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, useTheme, CircularProgress, Modal, Box, IconButton,FormHelperText  } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie'; 
import { ThemeContext } from '../../theme-context';
import config from "../../config";
import CloseIcon from '@mui/icons-material/Close'; 
import Swal from 'sweetalert2';  
import NoTransactionsIllustration from '../../Assests/Transactions.png';  


export default function WalletComponent() {
  const { theme } = useContext(ThemeContext);
  const muiTheme = useTheme();

  const [walletData, setWalletData] = useState({
    total_balance: 0,
    withdraw_balance: 0,
    hold_balance: 0,
    dailyProfit:0
  });
  const [addMoneyAmount, setAddMoneyAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [quickAddAmounts] = useState([5000, 2500, 1000, 500]);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [upiId, setupiID] = useState(null);

  const [transactionId, setTransactionId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [referenceId, setReferenceId] = useState('');
  const [timeLeft, setTimeLeft] = useState(300);
  const [timerInterval, setTimerInterval] = useState(null);

  const getThemeColors = () => {
    return {
      text: theme === 'dark' ? muiTheme.palette.common.white : muiTheme.palette.text.primary,
      cardBackground: theme === 'dark' ? 'rgba(134, 239, 172, 0.1)' : '#f0fdf4',
      cardText: theme === 'dark' ? muiTheme.palette.common.white : '#374151',
      inputBackground: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
      borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
    };
  };

  const colors = getThemeColors();

  const fetchData = async () => {
    const token = Cookies.get('userToken');
    const userId = Cookies.get('userId');

    if (token && userId) {
      try {
        const apiUrlWallet = `${config.apiBaseUrl}/user/getWallet`;
        const walletResponse = await axios.get(apiUrlWallet, {
          headers: {
            'x-access-token': token,
            'user-id': userId,
          }
        });
        setWalletData(walletResponse.data.wallet);

        const apiUrlTransaction = `${config.apiBaseUrl}/user/getTransactionHistory`;
        const transactionResponse = await axios.get(apiUrlTransaction, {
          headers: {
            'x-access-token': token,
            'user-id': userId,
          }
        });
        setTransactionData(transactionResponse.data.wallet);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); 
      }
    } else {
      console.error('Token or User ID not found in cookies');
      setLoading(false);  
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddMoney = async () => {
    const token = Cookies.get('userToken');
    const userId = Cookies.get('userId');

    if (!addMoneyAmount || isNaN(addMoneyAmount) || Number(addMoneyAmount) <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter a valid amount.',
      });
      return;
    }

    try {
      const apiUrl = `${config.apiBaseUrl}/user/initiateAddBalance`;
      const response = await axios.post(apiUrl,
        {
          amount: Number(addMoneyAmount),
          userId: userId
        },
        {
          headers: {
            'x-access-token': token,
          }
        }
      );

      if (response.status === 200) {
        setQrCodeUrl(response.data.qrCodeUrl);
        setupiID(response.data.upiId);

        setTransactionId(response.data.transactionId);
        setOpenModal(true); 
        startTimer();
        fetchData(); 
      } else if (response.status === 401) {
        Swal.fire({
          icon: 'warning',
          title: 'KYC Pending',
          text: 'Your KYC is still pending. Please complete the process to proceed.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to add money.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Unable to add money. Please try again later Or Check Your Kyc Status.',
      });
    }
  };

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(interval);
          closeModal();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    setTimerInterval(interval);
  };

  const handleWithdrawMoney = async () => {
    const token = Cookies.get('userToken');
    const userId = Cookies.get('userId');

    if (!withdrawAmount || isNaN(withdrawAmount) || Number(withdrawAmount) <= 0 || Number(withdrawAmount) > walletData.withdraw_balance) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter a valid withdraw amount.',
      });
      return;
    }

    try {
      const apiUrl = `${config.apiBaseUrl}/user/withdrawBalance`;
      const response = await axios.post(apiUrl,
        {
          amount: Number(withdrawAmount),
          userId: userId
        },
        {
          headers: {
            'x-access-token': token,
          }
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Money withdrawn successfully!',
        });
        fetchData();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to withdraw money.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Unable to withdraw money. Please try again later.',
      });
    }
  };

  const handleReferenceSubmit = async () => {
    const token = Cookies.get('userToken');
    const userId = Cookies.get('userId');
    
    try {
      const apiUrl = `${config.apiBaseUrl}/user/submitReferenceId`;
      const response = await axios.post(apiUrl,
        {
          userId : userId,
          referenceId: referenceId,
          transactionId :transactionId,
        },
        {
          headers: {
            'x-access-token': token,
          }
        }
      );

      if (response.status === 200) {
        closeModal();  
      } else {
        console.log("Error Ocurred");
      }
    } catch (error) {
      console.log("Error Ocurred");
    }
  };

  const closeModal = () => {
    clearInterval(timerInterval);
    setOpenModal(false);
    setQrCodeUrl(null);
    setupiID("");
    setReferenceId('');
    setTimeLeft(300); 
  };

  if (loading) {
    return (
      <Container maxWidth="lg" style={{ padding: '24px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '800px' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" style={{ padding: '24px', height: '100%', overflowY: 'auto', color: colors.text, minHeight: '600px' }}>
      <div style={{ marginBottom: '24px' }}>
        <div className="flex items-center gap-2 mb-6" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Wallet className="w-6 h-6" style={{ color: colors.text }} />
          <Typography variant="h5" component="h1" style={{ fontWeight: 500, color: colors.text }}>
            Wallet
          </Typography>
        </div>

        <Grid container spacing={3}>
          {['Available To Trade',  'Available To Withdraw', 'Todays Activity'].map((title, index) => {
            let amount = 0;
            switch (index) {
              case 0: amount = walletData.hold_balance; break;
              case 1: amount = walletData.withdraw_balance; break;
              case 2: amount = walletData.dailyProfit; break;
              default: break;
            }

            if (title === "Todays Activity" && amount === 0) {
              return null; 
            }

            if (title === "Available To Withdraw" ) {
              return null;
            }
            return (
              <Grid item xs={12} md={4} key={title}>
                <Card style={{ backgroundColor: colors.cardBackground, borderRadius: '16px', boxShadow: 'none', border: `1px solid ${colors.borderColor}` }}>
                  <CardContent>
                    <Typography variant="body2" component="p" gutterBottom style={{ color: colors.cardText }}>
                      {title}
                    </Typography>
                    <Typography variant="h4" component="p" style={{ fontWeight: 600, color: colors.cardText }}>
                      ₹{amount.toFixed(2)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        <Grid container spacing={4} style={{ marginTop: '24px' }}>
          <Grid item xs={12}>
            <Typography variant="body2" style={{ color: colors.text }} gutterBottom>Amount to Deposit</Typography>
            <Typography variant="caption" style={{ color: colors.text }} display="block" gutterBottom>Total ₹{walletData.total_balance}</Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
              <Typography variant="h4" style={{ marginRight: '8px', color: colors.text }}>₹</Typography>
              <Input
                type="number"
                value={addMoneyAmount}
                onChange={(e) => setAddMoneyAmount(e.target.value)}
                disableUnderline
                style={{
                  fontSize: '2rem',
                  borderBottom: `1px solid ${colors.borderColor}`,
                  marginRight: '16px',
                  width: '300px',
                  color: colors.text,
                  backgroundColor: colors.inputBackground
                }}
              />
              <Button variant="contained" color="primary" style={{ borderRadius: '24px', padding: '8px 24px' }} onClick={handleAddMoney}>
                Add
              </Button>
            </div>
            <div style={{ display: 'flex', gap: '8px', marginTop: '16px', flexWrap: 'wrap' }}>
              {quickAddAmounts.map((amount) => (
                <Button
                  key={amount}
                  variant="outlined"
                  onClick={() => setAddMoneyAmount(amount.toString())}
                  style={{ borderRadius: '24px', borderColor: colors.borderColor, color: colors.text }}
                >
                  +{amount}
                </Button>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '24px' }}>
            <Typography variant="body2" style={{ color: colors.text }} gutterBottom>Withdraw Balance</Typography>
            <Typography variant="caption" style={{ color: colors.text }} display="block" gutterBottom>Hold Balance ₹{walletData.hold_balance}</Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
              <Typography variant="h4" style={{ marginRight: '8px', color: colors.text }}>₹</Typography>
              <Input
                type="number"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
                disableUnderline
                style={{
                  fontSize: '2rem',
                  borderBottom: `1px solid ${colors.borderColor}`,
                  marginRight: '16px',
                  width: '300px',
                  color: colors.text,
                  backgroundColor: colors.inputBackground
                }}
              />
              <Button variant="outlined" style={{ borderRadius: '24px', padding: '8px 24px', borderColor: colors.borderColor, color: colors.text }} onClick={handleWithdrawMoney}>
                Withdraw funds
              </Button>
            </div>
          </Grid>
        </Grid>

        <div style={{ marginTop: '48px' }}>
          <Typography variant="h6" component="h2" style={{ color: colors.text }}>Transactions</Typography>
          
          {/* Conditional rendering for empty transactions */}
          {transactionData.length === 0 ? (
            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              <img src={NoTransactionsIllustration} alt="No transactions" style={{ maxWidth: '100%', height: 'auto' }} />
            </div>
          ) : (
            <TableContainer component={Paper} style={{ boxShadow: 'none', border: `1px solid ${colors.borderColor}`, backgroundColor: 'transparent' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: colors.text, fontWeight: 500 }}>Date</TableCell>
                    <TableCell style={{ color: colors.text, fontWeight: 500 }}>Description</TableCell>
                    <TableCell style={{ color: colors.text, fontWeight: 500 }}>Status</TableCell>
                    <TableCell align="right" style={{ color: colors.text, fontWeight: 500 }}>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionData.map((transaction) => (
                    <TableRow key={transaction.date + transaction.amount}>
                      <TableCell style={{ color: colors.text }}>
                        {new Date(transaction.createdAt).toLocaleDateString()}
                      </TableCell>
                      <TableCell style={{ color: colors.text }}>
                        {transaction.description}
                      </TableCell>
                      <TableCell style={{ color: colors.text }}>
                        <span
                          style={{
                            display: 'inline-block',
                            padding: '2px 8px',
                            borderRadius: '9999px',
                            fontSize: '0.75rem',
                            fontWeight: 500,
                            backgroundColor: transaction.status === "completed" 
                              ? (theme === 'dark' ? 'rgba(6, 95, 70, 0.5)' : 'rgba(222, 247, 236, 0.5)')
                              : transaction.status === "failed"
                              ? (theme === 'dark' ? 'rgba(220, 38, 38, 0.5)' : 'rgba(254, 202, 202, 0.5)')  
                              : transaction.status === "pending"
                              ? (theme === 'dark' ? 'rgba(234, 179, 8, 0.5)' : 'rgba(255, 252, 198, 0.5)')  
                              : (theme === 'dark' ? 'rgba(146, 64, 14, 0.5)' : 'rgba(254, 243, 199, 0.5)'),
                            color: transaction.status === "completed" 
                              ? (theme === 'dark' ? '#34d399' : '#03543f')
                              : transaction.status === "failed"
                              ? (theme === 'dark' ? '#f87171' : '#9b2c2c') 
                              : transaction.status === "pending"
                              ? (theme === 'dark' ? '#fbbf24' : '#b45309') 
                              : (theme === 'dark' ? '#fbbf24' : '#92400e'),
                          }}
                        >
                          {transaction.status}
                        </span>
                      </TableCell>
                      <TableCell align="right" style={{ color: colors.text }}>
                        ₹{transaction.amount.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>

      {/* QR Code Modal */}
      <Modal open={openModal} onClose={(e) => e.stopPropagation()}>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '24px',
            borderRadius: '12px',
            boxShadow: muiTheme.shadows[5],
            maxWidth: '400px',
            textAlign: 'center',
          }}
        >
          <IconButton
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
            }}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" style={{ color: colors.text }}>Complete the Payment</Typography>
          <div style={{ textAlign: 'center' }}>
            <img src={qrCodeUrl} alt="QR Code" style={{ maxWidth: '100%', height: 'auto' }} />
            <Typography variant="h6" component="h2" style={{ color: colors.text }}>{upiId}</Typography>

          </div>

          <div style={{ marginTop: '16px' }}>
            <Typography variant="body2" style={{ color: colors.text }} gutterBottom>
              Time left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
            </Typography>

            <div style={{ marginBottom: '8px' }}>
              <Input
                value={referenceId}
                onChange={(e) => setReferenceId(e.target.value)}
                placeholder="Enter reference ID"
                disableUnderline
                style={{
                  fontSize: '1rem',
                  border: `1px solid ${colors.borderColor}`, 
                  borderRadius: '8px', 
                  padding: '10px', 
                  width: '100%',
                  color: colors.text,
                  backgroundColor: colors.inputBackground
                }}
              />
              <FormHelperText style={{ color: colors.helperTextColor }}>
                Enter the reference ID provided with your payment.
              </FormHelperText>
            </div>
            <Button
              variant="contained"
              color="primary"
              style={{ borderRadius: '24px', padding: '8px 24px' }}
              onClick={handleReferenceSubmit}
              disabled={!referenceId.trim()}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </Container>
  );
}
